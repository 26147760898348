import React from "react";

// import Test from "../../app-ui/devComponents/AdditionalForm/AdditionalForm";
// import Test from "../../app-ui/devComponents/ModalForms/ModalFrom";
// import Test from "../../app-ui/devComponents/NotificationSetting/NotifSettings";
// import Test from "../../app-ui/devComponents/ExpModalForm/ExpForm";
import Test from "../../app-ui/devComponents/ModalCertificateForm/ModalCertificateForm";

function TestComponent() {
  return (
    <div className="about">
      <Test />
    </div>
  );
}
export default TestComponent;
